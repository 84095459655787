<template>
  <div>
    <el-form
      :model="ruleForm"
      status-icon
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="用户名" prop="userName">
        <el-input
          type="text"  prefix-icon="el-icon-user"
          v-model="ruleForm.userName"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input
          type="password" prefix-icon="el-icon-lock"
          v-model="ruleForm.password"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >提交</el-button
        >
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { BASE_CONFIG } from "@/config/config";

export default {
  data() {
    return {
      ruleForm: {
        userName: "admin",
        password: "",
      },
      rules: {
        password: [{ required: true, message:'密码不能为空',trigger: "blur" }],
        userName: [{ required: true, message:'用户名不能为空',trigger: "blur" }],
      },
    };
  },
  methods: {
    submitForm(formName) {
      let param = new FormData(); //创建form对象
      param.append("userName", this.ruleForm.userName);
      param.append("password", this.ruleForm.password);
      this.$refs[formName].validate((valid) => {
        if (valid) {

        this.$axios
            .post(
                BASE_CONFIG.ROOT_URL + "index/login",
                param
            )
            .then((response) => {
                console.log(response.data);
                if(response.data.code == 200){
                   window.localStorage.setItem("myToken",response.data.data);//存储数据
                   this.$router.push('/')
                }
            })
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>